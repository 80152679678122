<template>
  <b-card>
    <FormBuilder
      label="Voucher"
      create
      :fields="fields"
      :loading-submit="$store.state.highlight.loadingSubmit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
          notes: 'You can use the event name or creator name.',
        },
        {
          key: 'Amount',
          label: 'Amount',
          type: 'number',
          required: true,
          notes: 'The voucher amount in gank gold.',
        },
        {
          key: 'limitUsage',
          label: 'Limit Usage',
          type: 'number',
          required: true,
          notes: 'Set the usage for each voucher code.',
        },
        {
          key: 'numOfVoucher',
          label: 'Total Voucher',
          type: 'number',
          required: true,
          notes: 'The number of vouchers that will be created.',
        },
        {
          key: 'startDate',
          label: 'Start Date',
          type: 'datetime',
          required: true,
        },
        {
          key: 'endDate',
          label: 'End Date',
          type: 'datetime',
          required: true,
        },
      ],
    }
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('voucher/create', {
        ...data,
      })
        .then(callback)
    },
  },
}
</script>

    <style scoped>

    </style>
